import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form } from 'react-bootstrap';
import { applyExhibitions } from "../../store/slices/exhibition-slice";
import cogoToast from 'cogo-toast';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  EmailIcon,
} from "react-share";

const ExhibitionPost = ({ exhibitionDetails, slug }) => {
  const productUrl = `https://www.mattisirvioartgalleria.com/exhibitions/${slug}`;
  const productTitle = exhibitionDetails.exhibition_title;
  const productDescription = exhibitionDetails.exhibition_short_description;
  const productImage = exhibitionDetails.exhibition_image;
  const encodedText = exhibitionDetails.exhibition_full_description;

  const fromdate = new Date(exhibitionDetails.exhibition_start_date);
  const fromformattedDate = fromdate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const todate = new Date(exhibitionDetails.exhibition_end_date);
  const toformattedDate = todate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const { message } = useSelector((state) => state.exhibition);
  // State to control modal visibility and form data
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    exhibition_id: exhibitionDetails.exhibition_title,
    artist_name: '',
    artist_email: '',
    artist_mobile: '',
    artist_description: '',
    painting_upload: null,
    painting_name: '',
    upload_portfolio: null,
  });

  const dispatch = useDispatch(); // Create dispatch instance

  // Function to open the modal
  const handleForm = () => {
    setModalShow(true);
  };

  // Function to close the modal
  const onCloseModal = () => {
    setModalShow(false);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // for file inputs, use the file
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // for text inputs, use the value
      }));
    }
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Dispatch the form data to redux
    dispatch(applyExhibitions(formData))
      .then((response) => {
        if (response?.payload?.data?.message !== '') {
          // Navigate to the 'my-account' page with state
          cogoToast.success("Exhibition applied successfully.", { position: "bottom-left" });
        } else {
          cogoToast.error("Failed to update Apply Exhibition.", { position: "bottom-left" });
        }
      })
      .catch((error) => {
        cogoToast.error("Error while Apply Exhibition :" + error, { position: "bottom-left" });
      });
    // Close modal after submission
    onCloseModal();
  };

  // Check if the current date is greater than the exhibition end date
  const currentDate = new Date();
  const isExhibitionClosed = currentDate > todate;

  return (
    <Fragment>
      <div className="blog-details-top blog21">
        <div className="blog-details-content">
          <div className="row">
            <div className="col-lg-4">
              <div className="blog-details-img">
                <img alt="" src={exhibitionDetails.exhibition_image} />
              </div>
            </div>
            <div className="col-lg-8">
              <h3>{exhibitionDetails.exhibition_title}</h3>
              <div className="blog-meta-2">
                <ul>
                  <li>
                    {fromformattedDate} To {toformattedDate}
                  </li>
                </ul>
              </div>
              <div dangerouslySetInnerHTML={{ __html: encodedText }} />
              <div className="blog21">
                <div className="item-empty-area__text">
                  <br />
                  {!isExhibitionClosed && (
                    <a
                      style={{ padding: '9px 61px' }}
                      onClick={handleForm}
                      href="javascript:void(0);"
                    >
                      Apply Exhibition
                    </a>
                  )}
                </div>
                <div className="share-buttons pro-details-social" style={{ padding: '17px 1px' }}>
                  <FacebookShareButton
                    url={productUrl}
                    quote={productTitle}
                    hashtag="#ProductShare"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={productUrl} title={productTitle}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <PinterestShareButton
                    url={productUrl}
                    media={productImage}
                    description={productDescription}
                  >
                    <PinterestIcon size={32} round />
                  </PinterestShareButton>
                  <EmailShareButton
                    subject={`Check out this product: ${productTitle}`}
                    body={`I found this product: ${productTitle} - ${productUrl}`}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
          <Modal show={modalShow} onHide={onCloseModal} className="product-quickview-modal-wrapper">
            <Modal.Header closeButton><h3>Apply Exhibition</h3></Modal.Header>
            <Modal.Body>
              <form onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="artistname">Artist Name <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id="artistname"
                        name="artist_name"
                        value={formData.artist_name}
                        onChange={handleInputChange}
                        placeholder="Artist Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="artistemail">Email <span className="text-danger">*</span></label>
                      <input
                        type="email"
                        className="form-control"
                        id="artistemail"
                        name="artist_email"
                        value={formData.artist_email}
                        onChange={handleInputChange}
                        placeholder="Artist Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="artistmobile">Artist Mobile <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id="artistmobile"
                        name="artist_mobile"
                        value={formData.artist_mobile}
                        onChange={handleInputChange}
                        placeholder="Artist Mobile"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label htmlFor="artistdescription">Artist Description <span className="text-danger">*</span></label>
                    <textarea
                      className="form-control"
                      id="artistdescription"
                      name="artist_description"
                      value={formData.artist_description}
                      onChange={handleInputChange}
                      rows="3"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label htmlFor="paintingUpload">Painting Upload <span className="text-danger">*</span></label>
                    <input
                      type="file"
                      className="form-control"
                      id="paintingUpload"
                      name="paintingUpload"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label htmlFor="paintingName">Painting Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="paintingName"
                      name="painting_name"
                      value={formData.painting_name}
                      onChange={handleInputChange}
                      placeholder="Painting Name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label htmlFor="portfolioUpload">Upload Portfolio (if any)</label>
                    <input
                      type="file"
                      className="form-control"
                      id="portfolioUpload"
                      name="portfolioUpload"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={onCloseModal}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Apply
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};

export default ExhibitionPost;
