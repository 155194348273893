import { Fragment,useEffect } from "react";
import { useParams, useLocation } from "react-router-dom"; 
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ExhibitionSidebar from "../../wrappers/blog/ExhibitionSidebar";
import BlogComment from "../../wrappers/blog/BlogComment";
import ExhibitionPost from "../../wrappers/blog/ExhibitionPost";
import { useSelector, useDispatch } from "react-redux";
import { getExhibitions,getExhibitionDetails } from "../../store/slices/exhibition-slice";
import { Link } from "react-router-dom";
const ExhibitionDetailsStandard = () => {
  let { pathname } = useLocation();
   let { slug } = useParams();
  const dispatch = useDispatch();
  const { exhibitionDetails, exhibitions } = useSelector((state) => state.exhibition);
   useEffect(() => {
              dispatch(getExhibitions());
      }, [dispatch]);
      
     useEffect(() => {
                if(slug){
                    dispatch(getExhibitionDetails(slug)); 
                }
                
        }, [dispatch,slug]);
  if (!exhibitionDetails) {
    // You can display a loading spinner or message here
    return <div>Loading...</div>;
  }
  const products = exhibitionDetails.products;
  console.log('products',exhibitionDetails.products);
  return (
    <Fragment>
      {exhibitionDetails && <SEO
        titleTemplate={exhibitionDetails.exhibition_title}
        description={exhibitionDetails.exhibition_short_description}
      />}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {exhibitionDetails && <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "Exhibitions", path: process.env.PUBLIC_URL + '/exhibitions' },
            {label: exhibitionDetails.exhibition_title, path: process.env.PUBLIC_URL + pathname }
          ]} 
        />}
        <div className="blog-area pt-100 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-12">
                <div className="blog-details-wrapper ml-20">
                  {/* blog post */}
                  {exhibitionDetails && <ExhibitionPost exhibitionDetails={exhibitionDetails} slug={slug} />}
                </div>
              </div>
              <div className="col-lg-0">
                {/* blog sidebar */}
                {/* <ExhibitionSidebar exhibitions={exhibitions} id={exhibitionDetails.id} /> */}
              </div>
            </div>
          </div>
        </div>

        {products.length > 0 && <div className="product-area pt-100">
              <div className="container">
                <div className="section-title text-center">
                  <h2>Exhibition Artworks</h2>
                  <p></p>
                </div>
                <div className="product-tab-list pt-30 pb-55 text-center nav nav-pills" role="tablist">
                  <div className="row">
                    
                  {products.length > 0 && products?.map(product => {
                return (<div className="col-xl-3 col-md-6 col-lg-4 col-sm-6">
                      <div className="product-wrap mb-25">
                        <div className="product-img">
                         <Link to={process.env.PUBLIC_URL + "/arts/" + product.product_slug}>
                            <img className="default-img" src={product.feature_image} alt={product.product_name} />
                          </Link>
                        </div>
                        <div className="product-content text-center">
                          <h3>
                             <Link to={process.env.PUBLIC_URL + "/arts/" + product.product_slug}>{product.product_name}</Link></h3>
                          <div className="product-price">
                            <span>﷼ {product.product_price}</span>
                          </div>
                        </div>
                      </div>
                    </div>)})}
                 
                   
                   
                  
        
                  </div>
                </div>
              </div>
            </div>}
      </LayoutOne>
    </Fragment>
  );
};

export default ExhibitionDetailsStandard;
