import { Fragment,useEffect } from "react"; 
import { useLocation } from "react-router-dom"; 
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import BannerOne from "../../wrappers/banner/BannerOne";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne";
import { getSoloExhibitionPage  } from "../../store/slices/general-slice";
import { useSelector,useDispatch } from "react-redux";

const SoloExhibitionsPage = () => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();

  const { soloExhibitionData, loading, error } = useSelector((state) => state.general);
  useEffect(() => {
    // Dispatch the thunk to fetch the data
    dispatch(getSoloExhibitionPage());
  }, [dispatch]);

  if (!soloExhibitionData) {
    // You can display a loading spinner or message here
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      <SEO
        titleTemplate="Buy Paintings Online | Matti Sirvio Galleria"
        description="Buy Wall Art and Wall Paintings Online in oman. Matti Sirvio Galleri  gives a distinctive range of wall-placing paintings, ornamental wall plates, and greater on the first-class charges."
      /> 
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb 
        title="Solo Exhibition"
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "Solo Exhibition", path: process.env.PUBLIC_URL + pathname }
          ]} 
        />

        {/* section title with text */}
        <SectionTitleWithText spaceTopClass="pt-20" spaceBottomClass="pb-95" 
        pageData={soloExhibitionData}
        />

     

      </LayoutOne>
    </Fragment>
  );
};

export default SoloExhibitionsPage;
