import PropTypes from "prop-types";
import React, { Fragment,useEffect } from "react"; 
import { useParams, useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import RelatedProduct from "../../wrappers/product/RelatedProduct";
import { useSelector, useDispatch } from "react-redux";
import { artist } from "../../store/slices/exhibition-slice";
import { getProduct } from "../../store/slices/product-slice";
import { Link } from "react-router-dom";

const Artist = () => {
      const dispatch = useDispatch();
      const { artists } = useSelector((state) => state.exhibition);
      useEffect(() => {  
            dispatch(artist());
            }, [dispatch]);

  if (!artists) {
    // You can display a loading spinner or message here
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
          <SEO
            titleTemplate="Artist"
            description="Artist"
          />
    
          <LayoutOne headerTop="visible">
            {/* breadcrumb */}
            <Breadcrumb 
              pages={[
                {label: "Home", path: process.env.PUBLIC_URL + "/" },
                {label: "Artist", path: process.env.PUBLIC_URL + "/artist" }
              ]} 
            />
    <div className="shop-area pt-20 pb-20">
      <div className="container">
        
        <div className="product-area pt-0">
      <div className="container">
        <div className="section-title text-center">
          <h2>OUR ARTIST</h2>
          <p>Our success is a result of our artist and building upon our artist expertise and creative style of art providing to our clients.</p>
        </div>
        <div className=" pt-30 pb-55  " role="tablist">
          <div className="row">
            
          {artists?.map(artist => {
        return (<div className="col-xl-3 col-md-6 col-lg-4 col-sm-6">
              <div className="product-wrap mb-25">
                <div className="product-img">
                 <Link to={process.env.PUBLIC_URL + "/artist/" + artist.artist_slug}>
                    <img className="default-img" src={artist.profile_image} alt={`${artist.first_name} ${artist.last_name}`} />
                  </Link>
                </div>
                <div className="product-content">
                  <h3>
                     <Link to={process.env.PUBLIC_URL + "/artist/" + artist.artist_slug}>{`${artist.first_name} ${artist.last_name}`}</Link></h3>
                  <div className="product-price">
                    <span>{artist.total_product} Art</span>
                  </div>
                </div>
              </div>
            </div>)})}
         
           
           
          

          </div>
        </div>
      </div>
    </div>


     
      </div>
    </div>
    </LayoutOne>
    </Fragment>
  );
};

Artist.propTypes = {
};

export default Artist;
