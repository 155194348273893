import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogFeaturedThreeSingle = ({ singlePost }) => {
  return (
    <div className="blog-wrap mb-30 scroll-zoom news">
      <div className="blog-img">
        <Link to={singlePost.article_pdf}>
          <img src={singlePost.article_image} alt="" />
        </Link>
      </div>

      <p className="three-blog">
            <Link to={singlePost.article_pdf}>
              {singlePost.article_title}
            </Link>
          </p>
          {/* <span className="span-news pb-10">{singlePost.article_release_date}</span> */}
      <div className="blog-content-wrap">
        <div className="news-link pt-10">
       
          <span >
          <img width="50px" height="50px" src={singlePost.press_logo} alt="" />
          
            <Link to={singlePost.article_pdf} className="news-a">
              {singlePost.press_name}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

BlogFeaturedThreeSingle.propTypes = {
  singlePost: PropTypes.shape({})
};

export default BlogFeaturedThreeSingle;
