import PropTypes from "prop-types";
import clsx from "clsx";

const SectionTitleWithText = ({ spaceTopClass, spaceBottomClass,pageData }) => {
  let htmlContent = '';
  let page_name = '';
  // Check if pageData.page_description exists and is not empty
  if (pageData && pageData.page_description) {
    htmlContent = pageData.page_description;
  }
  if (pageData && pageData.page_name) {
    page_name = pageData.page_name;
  }
  return (
    <div className={clsx("welcome-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div className="col-12">
          {/* {page_name && <h1>{page_name}</h1>} */}
          <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        </div>
      </div>
    </div>
  );
};

SectionTitleWithText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default SectionTitleWithText;
